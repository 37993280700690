<template>
  <div class="lsg">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right">
        <button class="btn" type="button" @click.prevent="showCalcModal(null, 'add', 'Новый калькулятор', null)">
          <PlusIco/>
          <span>Калькулятор</span>
        </button>
      </div>
    </div>
    <div class="main__content">
      <div class="tbl">
        <div class="tbl__header">
          <div class="tbl__filter">
            <label class="tbl__filter__item">
              <input type="text" v-model="q" placeholder="Номер калькулятора">
            </label>
            <label class="tbl__filter__item">
              <v-select
                appendToBody
                :options="userOptions"
                placeholder="Сотрудник"
                @input="onUserChange"
                :value="
                  filter.userId
                    ? {
                        value: filter.userId,
                        label: filterDropName.find(
                          (s) => s.user_id === filter.userId
                        ).name,
                      }
                    : null
                "
              >
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon />
                  </div>
                </template>
              </v-select>
            </label>
            <label class="tbl__filter__item">
              <v-select
                appendToBody
                :options="
                  extendedGroup.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                "
                placeholder="Подразделение"
                @input="onGroupChange"
                :value="
                  filter.groupId
                    ? {
                        value: filter.groupId,
                        label: extendedGroup.find(
                          (s) => s.id === filter.groupId
                        ).name,
                      }
                    : null
                "
              >
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon />
                  </div>
                </template>
              </v-select>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="main__content">
      <ApolloQuery
        :query="require('@/graphql/queries/AllCalculators.gql')"
        :variables="{
          free: false,
          q: this.q,
          rerenderKey: rerenderKey,
          filter: {
            user: this.filter.userId,
            groupId: this.filter.groupId
          },
          page,
          perPage
        }"
        @result="doneLoadingAllCalculators"
      >
        <template slot-scope="{ result: { error, data } }">
          <div class="messages apollo" v-if="!error && !data">
            <div class="messages__status">Загрузка...</div>
          </div>
          <div class="messages apollo" v-else-if="error">
            <div class="messages__status error">Ошибка загрузки данных. Обратитесь в тех. поддержку</div>
          </div>
          <div class="tbl" v-else-if="data">
            <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
              <vue-good-table
                :columns="columns"
                :rows="data.allCalculators || []"
                :sort-options="{
                  enabled: true,
                  initialSortBy: {field: 'created_date', type: 'desc'}
                }"
                :pagination-options="{enabled: true, perPage: perPage}"
              >
                <div slot="emptystate">Данных нет</div>
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field === 'name'"><a href="#" @click="showCalcModal(null, 'update', props.row.name, props.row, props.row.id)">{{ props.row.name }}</a></div>
                  <div v-else-if="props.column.field === 'product'"><a href="#" @click="showCalcModal(null, 'update', props.row.name, props.row, props.row.id)">{{ props.row.product }}</a></div>
                  <div v-else-if="props.column.field === 'price'"><a href="#" @click="showCalcModal(null, 'update', props.row.name, props.row, props.row.id)">{{ prettifyPrice(props.row.price) }}</a></div>
                  <div v-else-if="props.column.field === 'created_date'"><a href="#" @click="showCalcModal(null, 'update', props.row.name, props.row, props.row.id)">{{ props.row.created_date ? new Date(props.row.created_date).toLocaleDateString() : null }}</a></div>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="vgt-wrap__footer vgt-clearfix">
                    <div class="footer__row-count vgt-pull-left">
                      <span class="footer__row-count__label">Показано</span>
                      <select @change="perPageChanged(props)" v-model="perPage" class="footer__row-count__select">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option :value="200">200</option>
                      </select>
                    </div>
                  </div>
                  <paginate :click-handler="setPage" :page-count="pageCount" :value="page" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                    <span slot="prevContent">
                      <ArrowLeftIcon/>
                    </span>
                    <span slot="nextContent">
                      <ArrowRightIcon/>
                    </span>
                  </paginate>
                </template>
              </vue-good-table>
            </mq-layout>
            <mq-layout :mq="[ 'xsm', 'md' ]" class="tbl__container">
              <table>
                <tbody v-if="data.allCalculators && data.allCalculators.length">
                  <tr :key="index" v-for="(calc, index) in data.allCalculators">
                    <td class="tbl__content">
                      <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)" style="color: #FF8000">Наименование</a></div>
                      <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)" style="color: #FF8000">Продукт</a></div>
                      <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)" style="color: #FF8000">Стоимость</a></div>
                      <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)" style="color: #FF8000">Создано</a></div>
                    </td>
                    <td>
                      <div class="tbl__content">
                        <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ calc.name }}</a></div>
                        <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ calc.product }}</a></div>
                        <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ prettifyPrice(calc.price) }}</a></div>
                        <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ calc.created_date ? new Date(calc.created_date).toLocaleDateString() : null }}</a></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="5">Нет результатов</td>
                </tr>
                </tbody>
              </table>
            </mq-layout>
            <mq-layout :mq="[ 'xs', 'sm' ]" class="tbl__container tbl__mobile">
              <table>
                <tbody v-if="data.allCalculators && data.allCalculators.length">
                  <tr :key="index" v-for="(calc, index) in data.allCalculators">
                    <td>
                      <div class="tbl__content__container">
                        <div class="tbl__content">
                          <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ calc.name }}</a></div>
                          <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ calc.product }}</a></div>
                          <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ prettifyPrice(calc.price) }}</a></div>
                          <div><a href="#" @click="showCalcModal(null, 'update', calc.name, calc, calc.id)">{{ calc.created_date ? new Date(calc.created_date).toLocaleDateString() : null }}</a></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="1">Нет результатов</td>
                </tr>
                </tbody>
              </table>
            </mq-layout>
            <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md' ]" class="tbl__footer">
              <div class="tbl__footer__item left"></div>
              <div class="tbl__footer__item">
                <paginate :page-count="pageCount" :value="page" :click-handler="setPage" prev-text="&lsaquo;" next-text="&rsaquo;" container-class="tbl__pagination vgt-pull-right">
                  <span slot="prevContent">
                    <ArrowLeftIcon/>
                  </span>
                  <span slot="nextContent">
                    <ArrowRightIcon/>
                  </span>
                </paginate>
              </div>
              <div class="tbl__footer__item right">
                <label class="tbl__pagination__info">
                  Показано
                  <span class="bold">{{ perPage }}</span>
                  из
                  <span class="bold">{{ allCalculatorsMeta }}</span>
                </label>
              </div>
            </mq-layout>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>

<script>
import PlusIco from '@/components/svg/plus'
import DownIcon from '@/components/svg/DownIcon'
// import gql from 'graphql-tag'
import FILTER from '@/graphql/queries/GetFilterUsers.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ArrowLeftIcon from '@/components/svg/arrow_left'
import ArrowRightIcon from '@/components/svg/arrow_right'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Calculators',
  components: {
    PlusIco,
    DownIcon,
    ArrowRightIcon,
    ArrowLeftIcon
  },
  data () {
    return {
      title: 'Калькуляторы',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Калькуляторы'
        }
      ],
      q: '',
      page: 1,
      perPage: 50,
      allCalculatorsMeta: 50,
      filterDropName: [],
      filterDropGroup: [],
      filter: {
        userId: null,
        groupId: null
      },
      columns: [
        { label: 'Заголовок', field: 'name', hidden: false },
        { label: 'Дата создания', field: 'created_date', hidden: false },
        { label: 'Имущество', field: 'product', hidden: false },
        { label: 'Стоимость', field: 'price', hidden: false }
      ]
    }
  },
  computed: {
    rerenderKey () {
      return this.$store.state.data.trades.rerenderCalcKey
    },
    pageCount () {
      return Math.ceil(this.allCalculatorsMeta / this.perPage)
    },
    userOptions () {
      if (this.filter.groupId) {
        return this.filterDropName
          .filter(elem => elem.group_id === this.filter.groupId)
          .map((item) => ({
            value: item.user_id,
            label: item.name
          }))
      } else {
        return this.filterDropName
          .map((item) => ({
            value: item.user_id,
            label: item.name
          }))
      }
    },
    extendedGroup () {
      const group = [...this.filterDropGroup]
      group.push({
        name: 'Филиалы',
        id: [
          '57a91d60-1c2e-11e2-8296-00304866b623',
          'dee2beee-4aac-11de-a104-00304866b623',
          '74cb0892-681c-11e9-bb4f-bcee7be31495',
          '1814a48e-afa1-11ea-80bf-00259047d1e4',
          'e5786ff6-4aac-11de-a104-00304866b623',
          '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
          'e578711c-4aac-11de-a104-00304866b623',
          'e57870a3-4aac-11de-a104-00304866b623',
          '9493c05d-4e93-11de-866b-001b38e49421',
          'e5787009-4aac-11de-a104-00304866b623',
          'dc096dea-feb3-11ec-80c9-00259047d1e4',
          '936c25c0-835c-11ec-80c6-00259047d1e4']
      })
      group.push({
        name: 'Филиалы Татарстан',
        id: ['57a91d60-1c2e-11e2-8296-00304866b623', 'dee2beee-4aac-11de-a104-00304866b623', 'e5787009-4aac-11de-a104-00304866b623', '936c25c0-835c-11ec-80c6-00259047d1e4']
      })
      group.push({
        name: 'Филиалы не Татарстан',
        id: [
          '74cb0892-681c-11e9-bb4f-bcee7be31495',
          '1814a48e-afa1-11ea-80bf-00259047d1e4',
          'e5786ff6-4aac-11de-a104-00304866b623',
          '1d1fe2c7-10cb-11e9-a962-bcee7be31495',
          'e578711c-4aac-11de-a104-00304866b623',
          'e57870a3-4aac-11de-a104-00304866b623',
          '9493c05d-4e93-11de-866b-001b38e49421',
          'dc096dea-feb3-11ec-80c9-00259047d1e4']
      })
      group.push({
        name: 'Казань офис продаж',
        id: ['245be5c7-26b6-11e9-bb4f-bcee7be31495', '32a431c7-26b6-11e9-bb4f-bcee7be31495']
      })
      // group.push({
      //   name: 'Дивизион РТ',
      //   id: ['dee2beee-4aac-11de-a104-00304866b623', 'e5787009-4aac-11de-a104-00304866b623']
      // })
      // group.push({
      //   name: 'Дивизион РБ',
      //   id: ['9493c05d-4e93-11de-866b-001b38e49421', 'e5786ff6-4aac-11de-a104-00304866b623', '3ae891c5-719c-11ee-80c9-00259047d1e4']
      // })
      return group
    }
  },
  beforeCreate () {
    if (this.$store.state.me && ['partner', 'agent'].includes(this.$store.state.me.role)) {
      this.$router.push('/')
    }
  },
  async created () {
    await this.widgetList()
  },
  methods: {
    doneLoadingAllCalculators ({ data }) {
      this.allCalculatorsMeta = data._allCalculatorsMeta.count
    },
    perPageChanged (props) {
      props.perPageChanged({ currentPerPage: this.perPage })
    },
    setPage (page) {
      this.page = page
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    async widgetList () {
      await this.$apollo.mutate({
        mutation: FILTER,
        variables: {},
        update: (store, { data }) => {
          if (data) {
            if (!this.filter.userId) {
              this.filterDropName = data.getFilterUsers.users
            }
            if (!this.filter.groupId) {
              this.filterDropGroup = data.getFilterUsers.groups
            }
            this.$nextTick(() => {
              this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
            })
            return true
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    onUserChange (e) {
      if (e) {
        this.filter.userId = e.value
        const responsible = this.filterDropName.find(elem => elem.user_id === e.value)
        this.filter.groupId = responsible.group_id
      } else {
        this.filter.userId = null
      }
    },
    onGroupChange (e) {
      if (e) {
        this.filter.groupId = e.value
        this.filter.userId = null
      } else {
        this.filter.groupId = null
      }
    },
    showCalcModal (leadId, type, header, data, calcId) {
      this.$store.state.data.trades.fullCalcModal = !this.$store.state.data.trades
        .fullCalcModal
      this.$store.state.data.trades.fullCalcDataModalProps.leadId = leadId
      this.$store.state.data.trades.fullCalcDataModalProps.calcId = calcId
      this.$store.state.data.trades.fullCalcDataModalProps.type = type
      this.$store.state.data.trades.fullCalcDataModalProps.header = header
      this.$store.state.data.trades.fullCalcDataModalProps.data = data
    },
    getRouteOfElementType (elementType) {
      if (elementType === 'Contact') return 'contact'
      if (elementType === 'Company') return 'company'
      if (elementType === 'Lead') return 'trade'
      if (elementType === 'Candidate') return 'candidate'
    },
    getStatusColor (item) {
      if (item.is_completed) {
        return ''
      } else {
        switch (this.getStatus(item.complete_till_at)) {
          case 'На сегодня':
            return 'tag--purple'
          case 'На завтра':
            return 'tag--green'
          case 'Эта неделя':
            return 'tag--orange'
          case 'След. неделя':
            return 'tag--dark-blue'
          // case 'После след. недели':
          //   return 'tag--yellow'
          case 'Просроченные':
            return 'tag--red'
        }
      }
    }
  }
}
</script>
